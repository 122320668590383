<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default uk-padding-small profile-wrapper">
      <div
        class="uk-grid-small"
        uk-grid
      >
        <div
          style="padding: 0 30px 0 32px;"
          class="uk-width-1-2@l uk-width-1-1@m"
        >
          <div class="profile-content uk-first-column">
            <label
              class="input-title uk-form-label"
              for="search-user-id"
            >User ID</label>
            <div class="uk-form-controls">
              <label
                id="search-user-id"
                class="label-field uk-input"
                type="text"
              >{{ dataUser.id }}</label>
            </div>
          </div>
          <div class="profile-content uk-first-column">
            <label
              class="input-title uk-form-label"
              for="search-name"
            >Nama User</label>
            <div class="uk-form-controls">
              <label
                id="search-name"
                class="label-field uk-input"
                type="text"
              >{{ dataUser.name }}</label>
            </div>
          </div>
          <div class="profile-content uk-first-column">
            <label
              class="input-title uk-form-label"
              for="search-email"
            >Email</label>
            <div class="uk-form-controls">
              <label
                id="search-email"
                class="label-field uk-input"
                type="text"
              >{{ dataUser.email }}</label>
            </div>
          </div>
          <div class="profile-content uk-first-column">
            <label
              class="input-title uk-form-label"
              for="search-phone-number"
            >No. Handphone</label>
            <div class="uk-form-controls">
              <label
                id="search-phone number"
                class="label-field uk-input"
                type="text"
              >{{ dataUser.phone }}</label>
            </div>
          </div>
        </div>
        <div
          style="padding: 0 32px 0 30px;"
          class="uk-width-1-2@l uk-width-1-1@m"
        >
          <div class="profile-content uk-first-column">
            <label
              class="input-title uk-form-label"
              for="search-position"
            >Jabatan</label>
            <div class="uk-form-controls">
              <label
                id="search-position"
                class="label-field uk-input"
                type="text"
              >{{ dataUser.position }}</label>
            </div>
          </div>
          <div class="profile-content uk-first-column">
            <label
              class="input-title uk-form-label"
              for="search-role"
            >Role</label>
            <div class="uk-form-controls">
              <label
                id="search-role"
                class="label-field uk-input"
                type="text"
              >{{ dataUser.role }}</label>
            </div>
          </div>
          <div class="profile-content uk-first-column">
            <label
              class="input-title uk-form-label"
              for="cari"
            >Foto Profil</label>
            <div
              style="width: 272px;"
              class="uk-form-controls"
            >
              <img
                :src="!dataUser.photo ? `${images}/avatar.png` : image_url+dataUser.photo"
                alt=""
                class="profil-img"
              >
            </div>
          </div>
        </div>
      </div>
      <div
        style="padding: 0 32px;"
        class="uk-width-2-2@s uk-text-right"
      >
        <button
          class="edit-btn uk-button uk-button-primary uk-margin-left"
          @click="showEdit()"
        >
          <img
            v-lazy="`${images}/icon/edit.svg`"
            alt=""
            class="edit-icon"
          >
          Ubah
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  props: {
    dataUser: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      image_url: process.env.VUE_APP_IMAGE_URL
    }
  },
  methods: {
    showEdit() {
      this.$router.push(`/admin/profile/edit`)
    }
  }
}
</script>

<style scoped>
.profile-wrapper {
  border-radius: 10px;
  box-shadow: 0 2px 20px #00000014;
  padding: 30px;
}
.profile-content {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}
.input-title {
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #025231;
}
.label-field {
  width: 340px;
  max-width: 340px;
  height: 40px;
  max-height: 40px;
  background: #F0F5F3;
  border-radius: 6px;
  color: #1F2E28;
}
.profil-img {
  width: 80px;
  max-width: 80px;
  height: 80px;
  max-height: 80px;
  border-radius: 40px;
}
.edit-btn {
  width: 124px;
  height: 40px;
  padding: 0;
}
.edit-icon {
  margin-right: 8px;
}
</style>
