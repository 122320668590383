<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-1-2@s font-header">
        {{ headerTitle }}
      </div>
    </div>

    <container :data-user="user" />
  </div>
</template>

<script>
import getHeaderTitle from '@/utils/header-title'
import Container from './Container'
import { dateString } from '@/utils/formater'
import { mapActions, mapGetters } from 'vuex'
import * as Cookies from 'js-cookie'

export default {
  components: {
    Container
  },
  data() {
    return {
      id: Cookies.get('paranje-id')
    }
  },
  computed: {
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    },
    ...mapGetters({
      user: 'user/user'
    })
  },
  async mounted() {
    await this.getUserDetail(this.id)
  },
  methods: {
    ...mapActions({
      getUserDetail: 'user/getUserDetail'
    }),
    getDatetime(date) {
      return dateString(date)
    }
  }
}
</script>
